import React from "react"
import {MDXProvider} from "@mdx-js/react"
import {MDXRenderer} from "gatsby-plugin-mdx"
import Seo from "../../composants/gabarits/seo";
import BaseGabarit from "./base";
import styled from "styled-components";
import {graphql} from "gatsby";

export const query = graphql`
    fragment ListePageContent on Mdx {
        body
        frontmatter {
            title
            banner {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            noIndex
        }
    }
`

const StyledLayout = styled(BaseGabarit)`
    main {
        > h2 {
          margin: 150px 0 100px;
        }
    
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        
        li {
            @media screen and (min-width: 800px) {
                > * {
                    width: 50%;
                    float: right;
                    clear: right;
                    box-sizing: border-box;
                    margin-left: 0;
                    margin-right: 0;
                }

                > p:first-child {
                    padding-right: 40px;
                    margin: 0;
                    float: left;
                }
            }
            
            h2, h3 {
                margin-top: 0;
                margin-bottom: 0;
            }
            
            & + * {
              border-top: 1px solid rgb(231,208,186, 0.15);
              padding-top: 50px;
              margin-top: 50px;            
            }
            
            &::after {
                display: block;
                content: "";
                clear: both;
            }
        }
        
        a {
          font-size: 0.9em;
          margin-top: 20px;
          display: inline-block;
          padding: 0.7em 1.5em;
          border: 2px solid #e7d0ba;
          color: #e7d0ba;
          text-transform: uppercase;
          text-decoration: none;
          
          &:hover {
              background-color: #e7d0ba;
              color: #000;
          }
      }
  }
`

export default function ListeGabarit({className, data: {page: {frontmatter, body}}}) {
    return (
        <StyledLayout banner={frontmatter.banner} className={className} noIndex={frontmatter.noIndex}>
            <Seo title={frontmatter.title}/>
            <h1>{frontmatter.title}</h1>
            <MDXProvider>
                <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
        </StyledLayout>
    )
}
